import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import BgImage from "../images/what-to-expect.jpg"
import Layout from "../components/layoutMainCustom"
import PageHeaders from "../components/pageHeaders"
import SEO from "../components/seo"
import ModalVideo from "react-modal-video"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

const MainButton = styled(Link)`
  color: #fff;
  background: #f07525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
`

const SecondaryButton = styled.button`
  color: #fff;
  background: #312e2e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  margin-left: 1.5rem;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`

class IndexPage extends React.Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal() {
    this.setState({ isOpen: true })
  }

  render() {
    return (
      <Layout
        ImgSrc={BgImage}
        MainText="What To Expect"
        SecondText="Biblical. Relevant. Missional."
      >
        <SEO title="What to Expect" />
        <PageHeaders
          css={css`
            display: flex;
          `}
        >
          God-Honoring Worship
        </PageHeaders>
        <HeaderText></HeaderText>
        <ParagraphText>
          At the heart of Cross Church is our understanding of worship. At Cross
          Church you will encounter the great hymns of our faith as well as
          songs right off the press. Whatever, we do in worship our goal is to
          bring glory to our great God. We have coined the phrase, “God-Honoring
          Worship” to describe our approach to corporate worship each week.
        </ParagraphText>
        <PageHeaders
          css={css`
            display: flex;
            margin-top: 4rem;
          `}
        >
          Christ-Centered Teaching
        </PageHeaders>
        <HeaderText>Biblical</HeaderText>
        <ParagraphText>
          Simply put we want to be a people of the Book. We believe as Jesus
          said, “That man should not live by bread alone, but by every word
          which comes out of His mouth.” This means that everything we do from
          our sermons, to our sermon-based Bible groups, to the way we are
          structured is built around the Word of God. We have a little slogan
          that guides us that says, “You are entitled to your own opinion but
          not to your own Bible.”
        </ParagraphText>
        <HeaderText>Relevant</HeaderText>
        <ParagraphText>
          Our church wants to always strive to speak and teach the timeless
          truths of scripture in such a way that you can get in our modern
          context. Remember if you don’t like relevance in church then you will
          like irrelevance a whole lot less.
        </ParagraphText>
        <HeaderText>Missional</HeaderText>
        <ParagraphText>
          We do not want you to just sit, soak, and sour, we want you to serve.
          At Cross Church the end goal for all of us is to use our giftedness to
          serve both here and around the world.
        </ParagraphText>
        <PageHeaders
          css={css`
            display: flex;
            margin-top: 4rem;
          `}
        >
          Cross Church Kids
        </PageHeaders>
        <HeaderText></HeaderText>
        <ParagraphText>
          Welcome to The Cross Church Kids Ministry where everything we do is
          focused on coming alongside you, as the parent, to lead children to a
          growing relationship with Jesus Christ. In this ministry, our children
          6 weeks to 6th grade experience a great time at church with a space,
          activities, music and Bible stories designed just for them.
        </ParagraphText>
        <HeaderText>Preschool @ Cross (Birth - Kindergarten)</HeaderText>
        <ParagraphText>
          Preschool @ Cross is our weekly environment created especially for
          your infants (six weeks) through Kindergarten age children. We know
          things are busy at this stage and your children are learning and
          growing in amazing ways. We can’t wait to meet them.
          <br />
          <br />
          We believe in an active, hands-on approach to learning with children.
          So, expect to see a lot of jumping, singing, and laughter as they
          learn about God. Before kids move into 1st grade, we want them to make
          sure three things stick:
          <br />
          <br />
          God MADE me.
          <br />
          God LOVES me.
          <br />
          Jesus wants to be my FRIEND forever.
        </ParagraphText>
        <HeaderText>Cross Church Kids (1st - 6th Grade)</HeaderText>
        <ParagraphText>
          Cross Church Kids is our weekly environment created especially for 1st
          - 6th grade. Kids dive-in each week in our specially designed space
          where we believe the Bible should never be boring, worship can be loud
          and fun, and children connect with group leaders to learn how God’s
          word fits into their everyday lives.
        </ParagraphText>
        <PageHeaders
          css={css`
            display: flex;
            align-items: center;
            margin-top: 4rem;
            padding-bottom: 0.5rem;
          `}
        >
          Learn More
          <ModalVideo
            channel="vimeo"
            videoId="704268092"
            isOpen={this.state.isOpen}
            onClose={() => this.setState({ isOpen: false })}
          />
          <SecondaryButton onClick={this.openModal}>
            <svg
              width="26"
              height="30"
              viewBox="0 0 26 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              css={css`
                margin-right: 1rem;
              `}
            >
              <path
                d="M18.8355 13.5213L3.21322 4.14221C1.94391 3.38052 0 4.11968 0 6.00361V24.7574C0 26.4475 1.80633 27.4661 3.21322 26.6188L18.8355 17.2441C20.2291 16.4103 20.2335 14.3551 18.8355 13.5213Z"
                fill="white"
              />
            </svg>
            Welcome!
          </SecondaryButton>
        </PageHeaders>
      </Layout>
    )
  }
}

export default IndexPage
